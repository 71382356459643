import {Component, inject, output} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {AuthService} from "../../../../core/services/auth.service";
import {UserStore} from "../../../../core/store/user-store";

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatIcon,
    RouterLinkActive,
    TranslateModule,
    NgOptimizedImage,
    MatButton
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss'
})
export class SidebarMenuComponent {

  closeMenu = output()
  authService = inject(AuthService)
  userStore = inject(UserStore)

  handleCloseMenu(){
    this.closeMenu.emit()
  }

  signOut(){
    this.authService.signOut();
  }

}
