/* src/app/layouts/components/smart-components/sidebar-menu/sidebar-menu.component.scss */
.sidenav__logo {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.sidebar-menu {
  background-color: #00022D;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 50px 0;
}
.sidebar-menu__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar-menu__item {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
.sidebar-menu__item a,
.sidebar-menu__item button {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 20px;
  width: 100%;
  transition: background-color 0.3s ease;
  background-color: #00022D;
  text-align: left;
}
.sidebar-menu__item a.active,
.sidebar-menu__item button.active {
  color: #32ef76;
  border-radius: 5px;
}
.sidebar-menu__item a:hover,
.sidebar-menu__item button:hover {
  color: #32ef76;
  border-radius: 5px;
}
.sidebar-menu__item a:focus,
.sidebar-menu__item button:focus {
  outline: none;
  color: #32ef76;
  border-radius: 5px;
}
.sidebar-menu__item mat-icon {
  margin-right: 10px;
  font-size: 24px;
}
.sidebar-menu__item span {
  font-size: 16px;
}
.sidebar-menu__item a.active mat-icon,
.sidebar-menu__item a.active span {
  color: #ffffff;
}
/*# sourceMappingURL=sidebar-menu.component.css.map */
